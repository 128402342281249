import React, { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import Confirmation from "./components/Confirmation";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import { useAuth } from "~/hooks/useAuth";

import Anticipation from "./Anticipation";
import Billet from "./Billet";
import CheckAnticipation from "./CheckAnticipation";
import CteAnticipation from "./CteAnticipation";
import Home from "./Home";
import NfseAnticipation from "./NfseAnticipation";
import Operations from "./Operations";
import Sendings from "./Sendings";
// import Pendings from "./Pendings";
import Profile from "./Profile";
import Prorrogation from "./Prorrogation";
import Banks from "./Register/Banks";
import Documents from "./Register/Documents";
import ECnpjs from "./Register/ECnpjs";
import FinancialReferences from "./Register/FinancialReferences";
import Geral from "./Register/Geral";
import Guarantors from "./Register/Guarantors";
import Repositories from "./Repositories";
import RepositoriesReturn from "./RepositoriesReturn";
import Service from "./Service";
import Tariffs from "./Tariffs";
import ConfigReturns from "./ConfigReturns";
import Titles from "./Titles";

import { Container } from "./styles";

import { OperationProvider } from "~/hooks/useOperation";
import CcbAnticipation from "./CcbAnticipation";
import ContractAnticipation from "./ContractAnticipation";
import dark from "./dark";
import light from "./light";
import AssignorFinish from "./Register/Finish";
import { FaMoneyBillWave } from "react-icons/fa";
import { normalizeCurrency } from "./utils/normalize";
import { api } from "~/hooks/api";

const AuthPage: React.FC = () => {
  const [theme, setTheme] = useState<string | null | undefined>(
    localStorage.getItem("theme_active")
  );
  const { currentAssignor } = useAuth();

  const [sideOpen, setSideOpen] = useState(true);

  const { balance, exceededPercentage } = useMemo(() => {
    const limit = currentAssignor.limit || 0;
    const open = currentAssignor.openValueTotal || 0;

    return {
      balance: limit - open,
      exceededPercentage: Math.abs((limit - open) / limit) * 100,
    };
  }, [currentAssignor]);

  function toggleTheme(label: string) {
    setTheme(label);
    localStorage.setItem("theme_active", label);
  }

  return (
    <OperationProvider>
      <ThemeProvider theme={theme === "dark" ? dark : light}>
        <Container>
          <Confirmation />
          <Header toggleTheme={(e) => toggleTheme(e)} />
          <div className="container" id="section_main">
            <div className="grid_main">
              <div
                id="side_menu_container"
                className={`${sideOpen ? "opened" : ""}`}
                style={{ transition: "500ms" }}
              >
                {currentAssignor.is_approved && (
                  <div
                    className="shadow limit-assignor"
                    style={{ borderRadius: "0.5rem" }}
                  >
                    <figure>
                      <img
                        src="/assets/images/icon-money.png"
                        alt="Money Icon"
                      />
                    </figure>

                    <div>
                      <h4>Limite disponível</h4>
                      <span>{normalizeCurrency(balance, true)}</span>
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  id="bars_button"
                  onClick={() => setSideOpen(!sideOpen)}
                >
                  <i className={`bar ${sideOpen ? "times" : ""}`} />
                  <i className={`bar ${sideOpen ? "times" : ""}`} />
                  <i className={`bar ${sideOpen ? "times" : ""}`} />
                </button>
                <SideMenu open={sideOpen} setOpen={(v) => setSideOpen(v)} />
              </div>
              <div style={{ transition: "500ms", maxWidth: "100%" }}>
                {balance < 0 && (
                  <div style={{ marginBottom: "15px", width: "inherited" }}>
                    <span
                      style={{ fontSize: "x-small" }}
                      className="alert alert-warning"
                    >
                      Seu limite atual está excedido em{" "}
                      {exceededPercentage.toFixed(2)}%
                    </span>
                  </div>
                )}
                <div>
                  <Switch>
                    <Route path="/dashboard" exact component={Home} />
                    <Route
                      path="/servicos/:current"
                      exact
                      component={Service}
                    />
                    <Route path="/tarifas/:current" exact component={Tariffs} />
                    <Route path="/titulos/:current" exact component={Titles} />
                    <Route path="/prorrogacao" exact component={Prorrogation} />
                    {/* <Route path="/pendencias" exact component={Pendings} /> */}
                    <Route path="/boletos" exact component={Billet} />
                    <Route path="/minha-conta" exact component={Profile} />
                    <Route path="/cadastro/geral" exact component={Geral} />
                    <Route
                      path="/cadastro/contas-corrente"
                      exact
                      component={Banks}
                    />
                    <Route
                      path="/cadastro/avalistas"
                      exact
                      component={Guarantors}
                    />
                    <Route
                      path="/cadastro/documentos"
                      exact
                      component={Documents}
                    />
                    <Route
                      path="/cadastro/referencias-financeiras"
                      exact
                      component={FinancialReferences}
                    />
                    <Route path="/cadastro/ecnpjs" exact component={ECnpjs} />
                    <Route
                      path="/cadastro/finalizar"
                      exact
                      component={AssignorFinish}
                    />
                    <Route
                      path="/antecipar-recebiveis/nfe"
                      exact
                      component={Anticipation}
                    />
                    <Route
                      path="/antecipar-recebiveis/cte"
                      exact
                      component={CteAnticipation}
                    />
                    <Route
                      path="/antecipar-recebiveis/cheque"
                      exact
                      component={CheckAnticipation}
                    />
                    <Route
                      path="/antecipar-recebiveis/nfse"
                      exact
                      component={NfseAnticipation}
                    />
                    <Route
                      path="/antecipar-recebiveis/ccb"
                      exact
                      component={CcbAnticipation}
                    />
                    <Route
                      path="/antecipar-recebiveis/contract"
                      exact
                      component={ContractAnticipation}
                    />
                    <Route
                      path="/operacoes/lista"
                      exact
                      component={Operations}
                    />
                    <Route path="/remessas" exact component={Sendings} />
                    <Route
                      path="/repositorios/remessas"
                      exact
                      component={Repositories}
                    />
                    <Route
                      path="/repositorios/retornos"
                      exact
                      component={RepositoriesReturn}
                    />
                    <Route
                      path="/configuracao-retorno"
                      exact
                      component={ConfigReturns}
                    />
                    <Redirect to="/dashboard" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Container>
      </ThemeProvider>
    </OperationProvider>
  );
};

export default AuthPage;
