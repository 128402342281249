import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { FaEdit, FaFilter, FaTimes } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { dateIsValid } from "~/pages/main/utils/normalize";

import {
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { useAuth } from "~/hooks/useAuth";
import { Autocomplete } from "../Autocomplete";
import { ListSkeleton } from "../Skeleton";
import { Container } from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface IProps {
  title: string | JSX.Element;
  filters: {
    name: string;
    title: string;
    select?: boolean;
    options?: {
      title: string;
      value: string | number;
    }[];
    defaultValue?: any;
    autocomplete?: boolean;
    autocompleteFunction?: (v: string) => Promise<any>;
    autocompleteTitle?: string;
    autocompleteValue?: string;
    transformToNumber?: boolean;
    normalize?: (value: any) => string;
    type?: "text" | "date";
    col?: number;
  }[];
  dataType: any;
  dataReciever: (params: any) => Promise<IProps["dataType"]>;
  labels: {
    name: string;
    title: string | JSX.Element;
    formater?: (value: any, obj: any) => any;
    noWrap?: boolean;
    ableToOrder?: boolean;
    align?: "left" | "right" | "center";
  }[];
  batchActions?: {
    title: string;
    onClick: (selected: any[]) => void;
  }[];
  editable?: boolean;
  editText?: string;
  traitResponse?: (data: any) => any;
  handleClickEdit?: (item: any) => void;
  addItem?: boolean;
  handleAddItem?: () => void;
  pagination?: boolean;
  aditionalActions?: (item: any) => JSX.Element;
  exportAction?: (params: any) => Promise<any>;
}

export const TableComponent: React.FC<IProps> = ({
  title,
  dataType,
  dataReciever,
  filters,
  labels,
  editText = "Editar",
  editable = false,
  handleClickEdit,
  traitResponse,
  batchActions = [],
  addItem = false,
  handleAddItem,
  pagination = true,
  aditionalActions,
  exportAction,
}) => {
  const [data, setData] = useState<typeof dataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [exporting, setExporting] = useState(false);
  const [values, setValues] = useState(() => {
    const obj: any = {};
    filters.forEach((f) => {
      obj[f.name] = f.defaultValue || "";
    });

    return obj;
  });
  const [order, setOrder] = useState({
    name: labels[0].name || "",
    direction: "desc",
  });
  const [showFilters, setShowFilters] = useState(true);

  const [selected, setSelected] = useState<any[]>([]);

  const { token } = useAuth();

  const getParams = () => {
    const params: any = {};
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        const filterObj = filters.find((e) => e.name === key);
        if (filterObj?.transformToNumber) {
          params[key] = Number(String(values[key]).replace(/[^\d]/g, ""));
        } else if (filterObj?.autocomplete) {
          params[key] = values[key][filterObj.autocompleteValue!];
        } else {
          params[key] = values[key];
        }
      }
    });
    params.page = page;
    params.per_page = perPage;
    params.order_name = order.name;
    params.order_direction = order.direction;
    return params;
  };

  async function loadData() {
    setLoading(true);
    try {
      const params: any = getParams();
      const d = await dataReciever(params);
      setData(traitResponse ? traitResponse(d.data) : d.data);
      setSelected([]);
      if (d?.meta?.total) {
        setTotal(d.meta.total);
      } else {
        setTotal(d.length);
      }
      if (d?.meta?.last_page) {
        setLastPage(d.meta.last_page);
      }
    } catch (err) {
      console.log("err component => ", err);
      //
    }
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [page, perPage, order.name, order.direction]); // eslint-disable-line

  const exportFunction = (export_type?: string) => {
    if (!exportAction) return;
    setExporting(true);
    exportAction({
      ...getParams(),
      export_type: export_type || "pdf",
      token,
    })
      .catch((err) => console.log(err))
      .finally(() => setExporting(false));
  };

  return (
    <Container className="d-flex flex-column">
      <div>
        <div className="d-flex justify-content-between align-items-center mt-0 mb-0 p-4">
          <div>
            <h5 className="m-0">
              <strong>{title}</strong>
            </h5>
          </div>
          <div className="d-flex">
            {loading && (
              <Spinner animation="border" size="sm" className="mr-4" />
            )}
            {exportAction && (
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  {exporting ? (
                    <>
                      <Spinner animation="border" /> Exportando
                    </>
                  ) : (
                    "Exportar"
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => exportFunction("pdf")}>
                    PDF
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => exportFunction("xmlx")}>
                    Excel
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {batchActions?.length > 1 ? (
              <Dropdown>
                <Dropdown.Toggle
                  className="mr-2 ml-2"
                  variant="outline-secondary"
                  id="batch-actions"
                >
                  Ações em Lote
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {batchActions.map((b) => (
                    <Dropdown.Item
                      key={b.title}
                      onClick={() => b.onClick(selected)}
                      disabled={selected.length === 0}
                    >
                      {b.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
            {batchActions?.length === 1 ? (
              <Button
                type="button"
                variant="secondary"
                className="mr-2 ml-2"
                onClick={() => batchActions[0].onClick(selected)}
                disabled={selected.length === 0}
              >
                {batchActions[0].title}
              </Button>
            ) : null}
            {/*
            {!showFilters && filters.length > 0 && (
              <Button
                type="button"
                onClick={() => setShowFilters(true)}
                variant="outline-primary"
                className="ml-2"
              >
                <FaFilter /> Exibir Filtros
              </Button>
            )}
            {showFilters && (
              <Button
                type="button"
                onClick={() => setShowFilters(false)}
                variant="outline-primary"
                className="ml-2"
              >
                <FaTimes /> Fechar Filtros
              </Button>
            )}
            */}
          </div>
        </div>
        {showFilters && (
          <div className="box_filters ml-4 mr-4 mb-4">
            <Row className="p-0">
              {filters.map((filter, index) => (
                <Col
                  lg={filter.col || 1}
                  key={String(index)}
                  className="mt-1 mb-1"
                >
                  {filter.select && (
                    <FormControl>
                      <InputLabel variant="standard" shrink>
                        {filter.title}
                      </InputLabel>
                      <NativeSelect
                        value={values[filter.name] || ""}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            [filter.name]: filter.normalize
                              ? filter.normalize(e.target.value)
                              : e.target.value,
                          })
                        }
                        inputProps={{
                          name: filter.name,
                          id: filter.name,
                        }}
                      >
                        <option value=""></option>
                        {filter.options?.map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.title}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  )}
                  {filter.autocomplete && filter.autocompleteFunction && (
                    <Autocomplete
                      onSelect={(item) =>
                        setValues({
                          ...values,
                          [filter.name]: item[filter.autocompleteValue as any],
                        })
                      }
                      columnTitle={filter.autocompleteTitle as any}
                      columnValue={filter.autocompleteValue as any}
                      loadResults={filter.autocompleteFunction}
                    />
                  )}
                  {filter.type === "date" && (
                    <>
                      <small className="d-block mb-1">{filter.title}</small>
                      <ReactDatePicker
                        locale="br"
                        title={filter.title}
                        selected={
                          dateIsValid(values[filter.name])
                            ? new Date(values[filter.name])
                            : undefined
                        }
                        onChange={(e) => {
                          setValues({
                            ...values,
                            [filter.name]: e as any,
                          });
                        }}
                        dateFormat="dd/MM/yyyy"
                        className="input-date-styled"
                        disabled={loading}
                      />
                    </>
                  )}
                  {!filter.select &&
                    filter.type !== "date" &&
                    !filter.autocomplete && (
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={filter.title}
                        value={values[filter.name] || ""}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            [filter.name]: filter.normalize
                              ? filter.normalize(e.target.value)
                              : e.target.value,
                          })
                        }
                        disabled={loading}
                      />
                    )}
                </Col>
              ))}
            </Row>
            <div className="d-flex justify-content-between mt-3">
              <Button
                type="button"
                onClick={() => {
                  setValues(() => {
                    const obj: any = {};
                    filters.forEach((f) => {
                      obj[f.name] = "";
                    });

                    obj.page = 1;
                    obj.per_page = 10;

                    return obj;
                  });
                }}
                variant="outline-primary"
              >
                <RiDeleteBin5Line /> Limpar Filtros
              </Button>
              <Button
                type="button"
                onClick={() => loadData()}
                variant="primary"
              >
                <FaFilter /> Filtrar
              </Button>
            </div>
          </div>
        )}
        <div className="contained">
          {!loading && (
            <Table responsive="lg" hover className="table-bfc">
              <thead>
                <tr>
                  {batchActions?.length > 0 ? (
                    <th style={{ width: "20px" }}>
                      <Form.Check
                        checked={selected.length === data.length}
                        onClick={() => {
                          if (selected.length === data.length) {
                            setSelected([]);
                          } else {
                            setSelected(
                              data.filter((d) => d.component_selectable)
                            );
                          }
                        }}
                        disabled={loading}
                      />
                    </th>
                  ) : null}
                  {labels.map((label, index) => (
                    <th
                      className={`${
                        label.noWrap ? "white-space-no-wrap" : ""
                      } ${index === 0 ? "pl-3" : "pl-1"} pr-1 pt-2 pb-2`}
                      key={label.name}
                    >
                      <div
                        className={`d-flex align-items-center ${
                          label.align === "right" ? "justify-content-end" : ""
                        }`}
                      >
                        <span>{label.title}</span>
                        {label.ableToOrder !== false && (
                          <div className="d-flex flex-column align-items-center justify-content-center arrows-order">
                            <button
                              type="button"
                              className={
                                order.name !== label.name ? "auto-opacity" : ""
                              }
                              onClick={() => {
                                setOrder({
                                  name: label.name,
                                  direction: "asc",
                                });
                              }}
                            >
                              <IoIosArrowUp
                                className={
                                  order.name === label.name &&
                                  order.direction === "asc"
                                    ? "text-primary"
                                    : "text-muted"
                                }
                              />
                            </button>
                            <button
                              type="button"
                              className={
                                order.name !== label.name
                                  ? "auto-opacity m-0"
                                  : "m-0"
                              }
                              onClick={() => {
                                setOrder({
                                  name: label.name,
                                  direction: "desc",
                                });
                              }}
                            >
                              <IoIosArrowDown
                                className={
                                  order.name === label.name &&
                                  order.direction === "desc"
                                    ? "text-primary"
                                    : "text-muted"
                                }
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                  {(editable || aditionalActions) && <th />}
                </tr>
              </thead>
              <tbody>
                {data?.length === 0 && (
                  <tr>
                    <td
                      colSpan={
                        labels.length + (editable || aditionalActions ? 1 : 0)
                      }
                    >
                      <h5 className="text-center">
                        Nenhum Registro Encontrado
                      </h5>
                    </td>
                  </tr>
                )}
                {data?.map((item, index) => {
                  const checked =
                    selected.findIndex((s) => s?.id === item?.id) > -1;
                  const onChangeCheck = () => {
                    if (loading) return;
                    if (!item.component_selectable) return;
                    let arr = [...selected];

                    if (checked) {
                      arr = [...arr].filter((a) => a.id !== item.id);
                    } else {
                      arr.push(item);
                    }

                    setSelected(arr);
                  };
                  return (
                    <tr
                      key={String(index)}
                      style={{ background: checked ? "#f0f7ff" : "" }}
                    >
                      {batchActions.length > 0 ? (
                        <td>
                          <Form.Check
                            checked={checked}
                            onClick={onChangeCheck}
                            disabled={!item.component_selectable || loading}
                          />
                        </td>
                      ) : null}
                      {labels.map((label, lindex) => (
                        <td
                          className={`${
                            label.noWrap ? "white-space-no-wrap" : ""
                          } small pr-1 pt-2 pb-2 ${
                            lindex === 0 ? "pl-3" : "pl-1"
                          } ${label.align === "right" ? "text-right" : ""} ${
                            label.align === "center" ? "text-center" : ""
                          }`}
                          key={label.name}
                          onClick={() => {
                            if (batchActions.length > 0) {
                              onChangeCheck();
                            }
                          }}
                        >
                          {label.formater
                            ? label.formater(item[label.name], item)
                            : item[label.name]}
                        </td>
                      ))}
                      {(editable || aditionalActions) && (
                        <td className="text-right">
                          <div className="d-flex align-items-center justify-content-end">
                            {editable && (
                              <Button
                                type="button"
                                onClick={() => {
                                  if (handleClickEdit) {
                                    handleClickEdit(item);
                                  }
                                }}
                                disabled={loading}
                                variant="primary"
                                size="sm"
                                className="p-1 d-flex ml-auto justify-content-center align-items-center"
                              >
                                <FaEdit className="m-1 mr-2" /> {editText}
                              </Button>
                            )}
                            {aditionalActions && aditionalActions(item)}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {loading && <ListSkeleton rows={10} cols={labels.length} />}
        </div>
      </div>
      {pagination && (
        <div className="d-flex align-items-center justify-content-between mb-4 pl-4 pr-4">
          <span>{total || data.length} Registros</span>
          <div className="d-flex align-items-end">
            {loading && (
              <Spinner size="sm" animation="border" className="mr-3 mb-3" />
            )}
            <Form.Group className="mb-0 mr-3">
              <Form.Control
                as="select"
                value={perPage}
                onChange={(e) => {
                  setPerPage(Number(e.target.value));
                  setPage(1);
                }}
                disabled={loading}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Form.Control>
            </Form.Group>
            <Pagination className="m-0">
              {page > 2 && (
                <Pagination.First
                  onClick={() => setPage(1)}
                  disabled={loading}
                />
              )}
              {page > 1 && (
                <Pagination.Prev
                  onClick={() => setPage(page - 1)}
                  disabled={loading}
                />
              )}
              <Pagination.Item>{page}</Pagination.Item>
              {page < lastPage && (
                <Pagination.Next
                  onClick={() => setPage(page + 1)}
                  disabled={loading}
                />
              )}
              {page + 1 < lastPage && (
                <Pagination.Last
                  onClick={() => setPage(lastPage)}
                  disabled={loading}
                />
              )}
            </Pagination>
          </div>
        </div>
      )}
    </Container>
  );
};
