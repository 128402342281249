import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import animationData from "~/animations/dashboard_animation.json";
import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";

import React, { Fragment, useEffect, useState } from "react";

import Lottie from "react-lottie";

import { IOperation } from "../Operations/operation";

import { differenceInDays, isBefore } from "date-fns";
import ModalDetail from "~/pages/main/Operations/components/ModalDetail";
import {
  normalizeCpfCnpj,
  normalizeCurrency,
  subString,
} from "../utils/normalize";
import ActionOperation from "./components/ActionOperation";
import ConsultCompany from "./components/ConsultCompany";
import ConsultProtest from "./components/ConsultProtest";
import MessageOperation from "./components/MessageOperation";
import Operations from "./components/Operations";
import { TitlesSharp, TitlesTable } from "./components/TitleOpen";
import { Container } from "./styles";

const Home: React.FC = () => {
  const [operations, setOperations] = useState<IOperation[]>([]);
  const [ecnpjExpired, setEcnpjExpired] = useState<boolean>(false);

  const [operation, setOperation] = useState({
    open: false,
    id: 0,
  });
  const { token, pendencies, currentAssignor } = useAuth();

  const [titles, setTitles] = useState<any[]>([]);
  const [payerConcentrations, setPayerConcentrations] = useState<
    {
      payer: any;
      titles_value: number;
      titles_amount: number;
    }[]
  >([]);

  async function loadDashboard() {
    setLoading(true);
    try {
      const { data: dataTitles } = await api.get(
        `/titles?situation=open&reduced=1&product_id=1`
      );
      setTitles(dataTitles);
      const { data } = await api.get(
        `/operations?operationStatusId=11&page=1&per_page=1`
      );
      setOperations(data.data);
      const { data: dataConcentration } = await api.get(
        "/payers_concentration"
      );
      setPayerConcentrations(dataConcentration);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState<
    {
      title: string;
      description: string;
    }[]
  >([]);
  const [validations, setValidations] = useState<
    {
      title: string;
      description: string;
    }[]
  >([]);

  useEffect(() => {
    async function loadData() {
      let arrW: any[] = [];
      let arrV: any[] = [];
      try {
        const { data } = await api.get("/register/assignor");
        if (!data.name) {
          arrV.push({
            title: "Razão Social [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.fantasy_name) {
          arrV.push({
            title: "Nome Fantasia [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.foundation_date) {
          arrV.push({
            title: "Data de Fundação [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.email) {
          arrV.push({
            title: "E-mail [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.zip_code) {
          arrV.push({
            title: "CEP [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.number) {
          arrV.push({
            title: "Número [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
      } catch (err) {
        //
      }
      try {
        const { data } = await api.get("/register/guarantors");
        if (data.length === 0) {
          arrW.push({
            title: "Sócios / Avalistas",
            description: "Necessário cadastrar no mínimo 1",
          });
        } else {
          data.forEach((guarantor, index) => {
            if (!guarantor.name) {
              arrV.push({
                title: `Nome / Razão Social [Avalista ${index + 1}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.document_number) {
              arrV.push({
                title: `CPF/CNPJ [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.birth_date) {
              arrV.push({
                title: `Data Nascimento / Fundação [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.zip_code) {
              arrV.push({
                title: `CEP [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.number) {
              arrV.push({
                title: `Número [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.phone) {
              arrV.push({
                title: `Telefone [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.rg_ie && guarantor.document_number?.length === 11) {
              arrV.push({
                title: `RG [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
          });
        }
      } catch (err) {
        //
      }
      try {
        const { data } = await api.get("/register/banks");
        if (data.length === 0) {
          arrW.push({
            title: "Contas Corrente",
            description: "Necessário cadastrar no mínimo 1",
          });
        }
      } catch (err) {
        //
      }
      try {
        const { data } = await api.get("/register/financialReferences");
        if (data.length === 0) {
          arrW.push({
            title: "Referências Financeiras",
            description: "Necessário cadastrar no mínimo 1",
          });
        }
      } catch (err) {
        //
      }
      try {
        const { data } = await api.get("/register/documents");
        if (data.length === 0) {
          arrW.push({
            title: "Documentos",
            description: "Nenhum documento enviado",
          });
        }
      } catch (err) {
        //
      }
      setWarnings(arrW);
      setValidations(arrV);

      try {
        const { data } = await api.get(`/register/ecnpjs?all=1`);

        setEcnpjExpired(
          isBefore(new Date(data[data.length - 1]?.expires_in), new Date())
        );
      } catch (err) {
        //
      }
    }
    loadData()
      .then(() => {
        loadDashboard();
      })
      .catch(() => setLoading(false));
  }, [token]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const openTitles = titles.filter(
    (t) => differenceInDays(new Date(String(t.business_date)), new Date()) >= 0
  );
  const expiredTitles = titles.filter(
    (t) => differenceInDays(new Date(String(t.business_date)), new Date()) < 0
  );

  const statusRegister = (
    <div className="row">
      <div className="col-md-12">
        {pendencies.length > 0 && !currentAssignor.is_approved && (
          <div className="bg-white shadow-sm p-4 radius-4 mb-4">
            <div>
              <h4>Seu cadastro está com pendências</h4>
              <p>Regularize seu cadastro para reanálise de nossos analistas.</p>
            </div>
          </div>
        )}
        {currentAssignor.is_finished &&
          !currentAssignor.is_approved &&
          pendencies.length === 0 && (
            <div>
              <p>
                Sua empresa está quase apta para operar conosco!
                <br />
                Analisaremos as informações de seu cadastro e quando possível{" "}
                <strong>aprovaremos</strong> para operações.
                <br />
                <strong className="text-muted">
                  Não se preocupe, iremos notificá-lo do processo por e-mail.
                </strong>
              </p>
            </div>
          )}
        {!currentAssignor.is_approved && warnings.length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <Alert severity="warning" className="mb-3">
                {warnings.map((w, index) => (
                  <div key={index} className="mb-2">
                    <strong className="d-block">{w.title}</strong>
                    <small>{w.description}</small>
                  </div>
                ))}
              </Alert>
            </div>
          </div>
        )}
        {!currentAssignor.is_approved && validations.length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <Alert severity="error" className="mb-3">
                {validations.map((w, index) => (
                  <div className="mb-2" key={index}>
                    <strong className="d-block">{w.title}</strong>
                    <small>{w.description}</small>
                  </div>
                ))}
              </Alert>
            </div>
          </div>
        )}
        {pendencies.length > 0 && !currentAssignor.is_approved && (
          <div className="row">
            <Alert severity="warning">
              Algumas pendências estão em aberto no seu cadastro. Conclua essas
              pendêcias para habilitar as <strong>operações</strong>.
            </Alert>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Container>
      <ModalDetail
        open={operation.open}
        operationId={operation.id}
        setOpen={() =>
          setOperation({
            open: false,
            id: 0,
          })
        }
      />
      {ecnpjExpired && (
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-warning">
              <strong>Atenção: E-CNPJ EXPIRADO</strong>
              <br />
              <br />

              <p>
                Ter e-CNPJ instalado em nossa plataforma agiliza a{" "}
                <strong>análise</strong> de seus recebíveis. Diminuindo as taxas
                e tarifas que incidem em suas operações.
              </p>
              <p className="mb-1">
                Click{" "}
                <a
                  href="/cadastro/ecnpjs"
                  className="text-body"
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  aqui
                </a>{" "}
                para atualizar seu e-CNPJ.
              </p>
            </div>
          </div>
        </div>
      )}
      {!loading ? (
        operations.length === 0 ? (
          <>
            {statusRegister}
            {currentAssignor.is_approved && (
              <>
                <div className="row">
                  <div className="col-12">
                    <MessageOperation />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-8">
                    <ActionOperation />
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="row">
              {statusRegister}
              {titles.length > 0 && (
                <div className="col-md-12 mb-3">
                  <div className="bg-white p-3 radius-4 shadow-sm">
                    <div className="row">
                      <div className="col-md-4" style={{ minHeight: "40vh" }}>
                        <TitlesSharp titles={titles} />
                      </div>
                      <div className="col-md-8">
                        <TitlesTable titles={titles} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {titles.length > 0 && (
                <>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="bg-white p-3 radius-4 shadow-sm">
                          <small className="d-block">
                            <strong>{titles.length}</strong> Em Aberto
                          </small>
                          <h5 className="mb-0 text-primary">
                            <strong>
                              R${" "}
                              {normalizeCurrency(
                                titles.reduce(
                                  (acc, item) => (acc += item.open_value),
                                  0
                                )
                              )}
                            </strong>
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="bg-white p-3 radius-4 shadow-sm">
                          <small className="d-block">
                            <strong>{openTitles.length}</strong> A Vencer
                          </small>
                          <h5 className="mb-0 text-warning">
                            <strong>
                              R${" "}
                              {normalizeCurrency(
                                openTitles.reduce(
                                  (acc, item) => (acc += item.open_value),
                                  0
                                )
                              )}
                            </strong>
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="bg-white p-3 radius-4 shadow-sm">
                          <small className="d-block">
                            <strong>{expiredTitles.length}</strong> Vencidos
                          </small>
                          <h5 className="mb-0 text-danger">
                            <strong>
                              R${" "}
                              {normalizeCurrency(
                                expiredTitles.reduce(
                                  (acc, item) => (acc += item.open_value),
                                  0
                                )
                              )}
                            </strong>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr />
                  </div>
                </>
              )}
              <div className="col-md-6 mb-3">
                <ConsultCompany />
              </div>
              <div className="col-md-6 mb-3">
                <ConsultProtest />
              </div>
              <div className="col-md-6 mb-3">
                <div className="bg-white p-3 radius-4 shadow-sm">
                  <Operations />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="bg-white p-3 radius-4 shadow-sm">
                  <h6 className="mb-4">
                    <strong>Concentração de Sacados</strong>
                  </h6>
                  <div
                    style={{ height: "35vh", overflow: "auto" }}
                    className="mt-2"
                  >
                    <table className="table table-sm table-striped table-hover mb-0 w-100 table-fixed-top">
                      <thead>
                        <tr>
                          <th className="small text-right"></th>
                          <th className="small text-right">Em aberto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payerConcentrations
                          ?.sort((a, b) => b.titles_value - a.titles_value)
                          ?.map((i) => (
                            <Fragment key={i.payer?.id}>
                              <tr>
                                <td colSpan={2} className="small">
                                  {normalizeCpfCnpj(i.payer?.document_number)} -{" "}
                                  {subString(i.payer?.name, 34)}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{ borderBottom: "2px solid #000" }}
                                  className="small"
                                >
                                  {i.titles_amount} Título(s)
                                </td>
                                <td
                                  className="text-right small"
                                  style={{ borderBottom: "2px solid #000" }}
                                >
                                  {normalizeCurrency(
                                    i?.titles_value || "000",
                                    true
                                  )}
                                </td>
                              </tr>
                            </Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <div className="loading" style={{ overflow: "hiden" }}>
          <Lottie options={defaultOptions} height={400} width={400} />
          <div className="text-center">
            <h2 className="ml-2">
              Estamos Montando seu Painel. Aguarde um instante...
            </h2>
            <CircularProgress size={20} />
          </div>
        </div>
      )}
    </Container>
  );
};

export default Home;
