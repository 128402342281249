import styled, { keyframes } from 'styled-components';

const AnimationFade = keyframes`
  0%{
    opacity:0;
    transform:translateY(10px);
  }100%{
    opacity:1;
    transform:translateY(0);
  }
`;

export const Container = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
  img {
    max-width: 100%;
  }
  div.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  div.widget-about {
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: ${AnimationFade} 500ms linear;
    animation-fill-mode: backwards;
    .widget-about__box_text {
      background: url('/assets/images/luminaria-vaso-luz.png') no-repeat;
      background-position: 0px -60px;
      width: 100%;
      max-width: 820px;
      display: flex;
      flex-direction: column;
      align-items: self-start;
      padding: 115px 120px 85px;
      @media screen and (max-width: 1625px) {
        margin: auto;
      }
      p {
        font: normal normal 300 24px/30px Montserrat;
        letter-spacing: 0px;
        color: #333333;
        margin: 0px;
        font-weight: bold;
      }
      strong {
        font: normal normal normal 26px/33px Montserrat;
        font-weight: bold;
        margin: 5px 0 10px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      button {
        background: #fafafa 0% 0% no-repeat padding-box;
        border-radius: 20px;
        padding: 10px 20px;
        font: normal normal normal 14px/16px Montserrat;
        font-weight: bold;
        letter-spacing: 0px;
        color: #3757a1;
      }
    }
    .widget-about__box_illustrator {
      display: flex;
      align-items: flex-end;
      @media screen and (max-width: 1625px) {
        display: none;
      }
      .lightFixture {
      }
      .actor {
      }
      .bigPlant {
        position: relative;
        left: 30px;
        display: none;
        @media screen and (min-width: 1700px) {
          display: block;
        }
      }
      .smallPlant {
        display: none;
        @media screen and (min-width: 1700px) {
          display: block;
        }
      }
    }
  }

  div.widget-anticipation-sale {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #3757a1;
    border-radius: 15px;
    padding: 50px 40px;
    display: flex;
    justify-content: space-between;
    animation: ${AnimationFade} 600ms linear;
    animation-fill-mode: backwards;
    .widget-anticipation-sale__box_text {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      padding: 10px 40px 0;
      p {
        font: normal normal 300 34px/45px Montserrat;
        font-weight: bold;
        letter-spacing: 0px;
        color: #333333;
        position: relative;
        strong {
          display: block;
          font: normal normal normal 39px/45px Montserrat;
          font-weight: bold;
          letter-spacing: 0px;
          color: #3757a1;
          margin-top: 5px;
        }
        &::before {
          content: '';
          background: url('/assets/images/smile.png') no-repeat;
          width: 70px;
          height: 70px;
          position: absolute;
          right: 20px;
          top: -30px;
        }
      }
      a {
        text-decoration: none;
        font: normal normal 300 19px/23px Montserrat;
        letter-spacing: 0px;
        color: #3757a1;
        border: 2px solid #3757a1;
        border-radius: 20px;
        padding: 10px 20px;
        line-height: 1;
      }
    }
    .widget-anticipation-sale__box_illustrator {
      padding: 0 40px;
    }
  }
  div.widget-whatsapp {
    background: rgb(202 212 226 / 0.31);
    border-radius: 15px;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: ${AnimationFade} 700ms linear;
    animation-fill-mode: backwards;
    position: relative;
    &::before {
      content: '';
      background: url('/assets/images/whatsapp.png') no-repeat;
      background-size: 50px 50px;
      width: 50px;
      height: 50px;
      position: absolute;
      left: 30px;
      top: 30px;
    }
    .widget-whatsapp__box_text {
      text-align: right;
      width: 45%;
      position: relative;
      left: 20px;
      top: 10px;
      p {
        font: 300 31px/36px Montserrat;
        letter-spacing: 0px;
        color: #333333;
        font-weight: bold;
        strong {
          display: block;
          font: 31px/36px Montserrat;
          font-weight: bold;
          letter-spacing: 0px;
          color: #3757a1;
        }
      }
      a {
        font: normal normal 300 18px/21px Montserrat;
        text-decoration: none;
        letter-spacing: 0px;
        color: #25d366;
        border: 2px solid #25d366;
        border-radius: 20px;
        padding: 10px 20px;
        line-height: 1;
      }
    }
    .widget-whatsapp__box_illustrator {
      img {
        max-width: 200px;
        margin: 0;
        position: relative;
        top: 10px;
        left: -15px;
      }
    }
  }

  div.widget-services {
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    display: flex;
    align-items: stretch !important;
    flex-direction: column;
    position: relative;
    word-wrap: break-word;
    background-color: rgb(55 87 161 / 10%);
    .card-body {
      background-position: left bottom;
      background-size: auto 100%;
      padding: 20px;
      h4 {
        font: normal normal bold 16px Montserrat;
        color: #555;
      }
      p {
        font: normal normal 300 11px Montserrat;
        color: #555;
      }
      a {
        font: normal normal 300 13px Montserrat;
        color: #fff;
        background: #3757a1;
      }
    }
  }

  div.widget-titles {
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: ${AnimationFade} 600ms linear;
    animation-fill-mode: backwards;
    .widget-titles__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4 {
        margin: 0;
      }
    }
    .widget-titles__box_body {
      width: 100%;
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: flex-end;
        li {
          margin-left: 10px;
          button {
            background: #f1f1f1;
            font-size: 12px;
            &.active {
              background: #3757a1;
              color: #fff;
            }
          }
        }
      }
      > div {
        width: 100%;
        height: 425px;
        overflow: scroll;
        padding-right: 10px;
        table {
          margin-top: 20px;
          width: 100%;
          thead {
            tr {
              th {
                padding: 10px;
                border: 1px solid #e9e9e9;
                font-size: 12px;
                line-height: 1.2;
                color: #555;
                background: #f1f1f1;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 10px 7.5px;
                border: 1px solid #e9e9e9;
                font-size: 12px;
                line-height: 1.2;
                color: #555;
                span {
                  font-weight: 500;
                  display: block;
                  font-size: 10px;
                  font-family: sans-serif;
                }
                small {
                  font: normal normal 300 9px Montserrat;
                  padding: 2.5px 7.5px;
                  border-radius: 4px;
                  &.avencer {
                    background: #e8c1a0;
                    color: #7e3a00;
                  }
                  &.ematraso {
                    background: #f47560;
                    color: #6b1304;
                  }
                  &.liquidados {
                    background: #f1e15b;
                    color: #807203;
                  }
                }
                &:first-child {
                  font-weight: bold;
                }
                &:last-child {
                  font-weight: bold;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
  div.widget-calendar {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #eceff3;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 25px 25px 20px;
    display: flex;
    justify-content: space-between;
    animation: ${AnimationFade} 600ms linear;
    animation-fill-mode: backwards;
    .widget-calendar__box {
      display: flex;
      flex-direction: column;
      width: 100%;
      .widget-calendar__box_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 10px;
        h4 {
          display: flex;
          flex-direction: column;
          font: normal normal 500 17px sans-serif;
          line-height: 1.4;
          color: #333;
          margin: 0;
          span {
            font-weight: 100;
            font-size: 12px;
            color: #555;
          }
        }
        a {
          background: rgb(255 185 1 / 20%);
          color: #c69106;
          font: normal normal 800 12px sans-serif;
        }
      }
      .widget-calendar__box_body {
        width: 100%;
        table {
          width: 100%;
          margin-top: 10px;
          tbody {
            tr {
              td {
                color: #555;
                font: normal normal 300 12px sans-serif;
                padding: 10px 0px;
                &:first-child {
                  font-size: 13px;
                  font-weight: bold;
                }
                &:last-child {
                  font-weight: bold;
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .widget-about {
      align-items: flex-end !important;
      .widget-about__box_text {
        width: 80% !important;
        padding: 55px 100px 40px 70px !important;
        background-size: 100% auto !important;
        p {
          font-size: 20px !important;
          line-height: 1 !important;
        }
        strong {
          font-size: 20px !important;
          margin-top: 10px !important;
          line-height: 1 !important;
        }
        button {
          font-size: 14px !important;
        }
      }
      .widget-about__box_illustrator {
        width: 20%;
        img {
          width: 100%;
        }
        .bigPlant,
        .smallPlant {
          display: none !important;
        }
      }
    }
  }
  @media screen and (max-width: 990px) {
    .widget-about,
    .widget-anticipation-sale {
      margin-bottom: 20px;
    }
    .widget-about {
      .widget-about__box_text {
        width: 100% !important;
        padding: 65px 100px 40px 70px !important;
      }
      .widget-about__box_illustrator {
        display: none !important;
      }
    }
    div.widget-anticipation-sale {
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    .widget-about {
      .widget-about__box_text {
        padding: 20px 20px !important;
        text-align: center;
        background: #3757a1 !important;
        border-radius: 15px;
        margin-bottom: 20px;
        width: 100% !important;
        p {
          width: 100%;
          font-size: 20px !important;
          line-height: 1 !important;
        }
        strong {
          width: 100%;
          font-size: 20px !important;
          margin-top: 10px !important;
          line-height: 1 !important;
        }
        button {
          font-size: 14px !important;
          margin: auto;
        }
      }
      .widget-about__box_illustrator {
        display: none !important;
      }
    }
    div.widget-anticipation-sale {
      padding: 60px 40px 40px !important;
      margin-bottom: 20px !important;
      .widget-anticipation-sale__box_text {
        padding: 0px !important;
        align-items: center;
        width: 100%;
      }
      .widget-anticipation-sale__box_illustrator {
        display: none !important;
      }
    }
    div.widget-whatsapp {
      padding: 15px 0 !important;
      .widget-whatsapp__box_illustrator {
        img {
          max-width: 100%;
          left: 0px;
        }
      }
    }
  }
  /* @media screen and (max-width: 1840px) {
    .widget-about {
      .widget-about__box_text {
        width: 60%;
      }
      .widget-about__box_illustrator {
        width: 40%;
      }
    }
  } */
`;
