import React, { memo, useEffect } from "react";

import { api } from "~/hooks/api";
import {
  formatDate,
  normalizeBrl,
  subString,
} from "~/pages/main/utils/normalize";
import {
  normalizeCpfCnpj,
  normalizeCurrency,
  normalizeNumber,
} from "../../utils";
import { TableComponent } from "~/pages/main/components/TableComponent";
import { useAuth } from "~/hooks/useAuth";

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = ({ handleNextItem }) => {
  const { getAllCompanies, companies } = useAuth();

  useEffect(() => {
    getAllCompanies();
  }, [])

  return (
    <TableComponent
      title="Tarifas em Aberto"
      dataReciever={async (params) => {
        const { data } = await api.get(`/assignor/tariffs`, {
          params: {
            ...params,
            situation: "open",
          },
        });
        return data;
      }}
      dataType={{}}
      filters={[
        {
          name: "title_code",
          title: "ID. Título",
        },
        {
          name: "start_date",
          title: "Vencimento (de)",
          type: "date",
          col: 2,
        },
        {
          name: "end_date",
          title: "Vencimento (até)",
          type: "date",
          col: 2,
        },
        {
          name: "payer_document",
          title: "CPF/CNPJ do Sacado",
          col: 2,
        },
        {
          name: "document_number",
          title: "Nr. Documento",
          col: 2,
        },
        {
          name: "start_value",
          title: "Valor (de)",
          normalize: (value) =>
            normalizeCurrency(Number(normalizeNumber(value))),
          transformToNumber: true,
        },
        {
          name: "end_value",
          title: "Valor (até)",
          normalize: (value) =>
            normalizeCurrency(Number(normalizeNumber(value))),
          transformToNumber: true,
        },
        {
          name: "company_id",
          title: "Empresa",
          select: true,
          defaultValue: 0,
          options: companies?.map((company) => ({
            title: `${company?.code} - ${company?.fantasy_name}`,
            value: company?.id
          })),
          col: 3,
        },
      ]}
      labels={[
        {
          name: "titles.code",
          title: "ID.Título",
          formater: (_value, tariff) => tariff?.title?.code,
        },
        {
          name: "operations.code",
          title: "Empresa",
          formater: (_value, tariff) => tariff?.company_code,
        },
        {
          name: "titles.document_number",
          title: "Nr.Documento",
          formater: (_value, tariff) => tariff?.title?.document_number,
        },
        {
          name: "payers.name",
          title: "Sacado",
          formater: (_value, tariff) => (
            <div className="white-space-no-wrap">
              {subString(tariff.title.payer.name, 34)}
              <br />
              {normalizeCpfCnpj(tariff.title.payer.document_number)}
            </div>
          ),
        },
        {
          name: "title_tariffs.open_value",
          title: "Valor",
          formater: (_value, tariff) => normalizeBrl(tariff.open_value),
        },
        {
          name: "title_tariffs.due_date",
          title: "Vencimento",
          formater: (_value, tariff) => formatDate(tariff.due_date),
        },
        {
          name: "tariff_types.name",
          title: "Tipo",
          formater: (_value, tariff) => tariff?.type?.name,
        },
      ]}
      exportAction={async (params) => {
        const obj = {
          ...params,
          payer_document: params.payer_document
            ? normalizeNumber(params.payer_document)
            : "",
          situation: "open",
          startValue: params.start_value
            ? normalizeNumber(params.start_value)
            : "",
          endValue: params.end_value ? normalizeNumber(params.end_value) : "",
        };
        window.open(
          `${process.env.REACT_APP_API}/assignor/tariffs?${Object.keys(obj)
            .filter((key) => obj[key])
            .map((key) => `${key}=${obj[key]}`)
            .join("&")}`
        );
      }}
    />
  );
};

export default memo(Open);
