import { api } from "~/hooks/api";
import React, { useState } from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import { AiOutlineUpload } from "react-icons/ai";
import { FaCheckCircle, FaClock, FaFile, FaTrash } from "react-icons/fa";
import { MdQueryBuilder, MdVisibility, MdWarning } from "react-icons/md";
import { toast } from "react-toastify";
import { useAuth } from "~/hooks/useAuth";
import { useModal } from "~/hooks/useModal";
import { format } from "date-fns";

// import { Container } from './styles';

interface IProps {
  type: any;
  items?: any[];
  onUpdate: (v: any) => void;
  updatable: boolean;
  deletable: boolean;
}

export const DocItem: React.FC<IProps> = ({
  type,
  items,
  onUpdate,
  updatable,
  deletable,
}) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const { pendencies, returnPendencies } = useAuth();
  const modal = useModal();
  const pend = pendencies.filter(
    (p) => p.type === "document" && p.document_type_id === type.id
  );

  async function handleSend(file: File) {
    setLoading(true);
    try {
      const obj = new FormData();
      obj.append("document_type_id", String(type.id));
      obj.append("file", file);
      const { data } = await api.post(`/register/documents`, obj, {
        onUploadProgress: function (progressEvent: any) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      onUpdate(data);

      await returnPendencies(pend);

      toast.info("Documento Enviado");
    } catch (err) {
      //
    }
    setLoading(false);
  }

  const item = (items && items[0]) || {};

  return (
    <div key={type.id} className="document_item shadow">
      <span
        className={`icon_status ${
          item?.url && pend.length === 0 ? "bg-success" : ""
        } ${!item?.url && pend.length === 0 ? "bg-secondary" : ""} ${
          pend.length > 0 ? "bg-warning" : ""
        }`}
      >
        {loading && <Spinner animation="border" />}
        {item?.url && !loading && pend.length === 0 && <FaCheckCircle />}
        {!item?.url && !loading && pend.length === 0 && <MdQueryBuilder />}
        {pend.length > 0 && <MdWarning />}
      </span>
      <div className="d-flex justify-content-between align-items-center pt-3 pb-4 pl-3 pr-3">
        {loading && <ProgressBar animated now={progress} />}

        <div className="d-flex align-items-start" style={{ maxWidth: "80%" }}>
          <div>
            <p className="d-block mr-2 mb-0">{type.name}</p>
            {item?.url && (
              <a
                href={item?.url}
                target="blank"
                className="btn btn-outline-primary btn-sm pt-0 pb-0 pl-1 pr-1 mr-2"
              >
                <MdVisibility /> Visualizar
              </a>
            )}
            {items && items.length > 0 ? (
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm pt-0 pb-0 pl-1 pr-1"
                onClick={() => {
                  modal.show({
                    title: "Histórico",
                    message: (
                      <div className="mb-3">
                        {items?.map((doc) => (
                          <div
                            key={doc.id}
                            className="d-flex justify-content-between align-items-center mb-2"
                          >
                            <label>
                              <a
                                href={doc.url}
                                target="blank"
                                className="btn d-flex align-items-center btn-outline-primary pt-0 pb-0 pl-1 pr-1"
                              >
                                <FaFile className="mr-1" /> Abrir
                              </a>
                            </label>
                            {deletable && (
                              <label
                                onClick={() => {
                                  modal.warning({
                                    title: "Excluir Documento",
                                    message: (
                                      <div>
                                        <p>
                                          Tem certeza que deseja excluir este
                                          documento?
                                        </p>
                                        <button
                                          type="button"
                                          onClick={async () => {
                                            await api.delete(
                                              `/register/documents/${doc.id}`
                                            );
                                            onUpdate(
                                              items.filter(
                                                (i) => i.id !== doc.id
                                              )
                                            );
                                            modal.dismiss();
                                          }}
                                          className="btn btn-danger"
                                        >
                                          Excluir
                                        </button>
                                      </div>
                                    ),
                                  });
                                }}
                                className="btn d-flex align-items-center btn-outline-danger pt-0 pb-0 pl-1 pr-1"
                              >
                                <FaTrash className="mr-1" /> Excluir
                              </label>
                            )}
                            <small>
                              {format(
                                new Date(doc.created_at),
                                "dd/MM/yyyy HH:mm"
                              )}
                            </small>
                          </div>
                        ))}
                      </div>
                    ),
                  });
                }}
              >
                <FaClock /> Histórico
              </button>
            ) : null}
          </div>
        </div>
        {updatable && (
          <label
            htmlFor={`file_upload_${type.id}`}
            className="btn btn-outline-secondary btn-sm"
          >
            <span>
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> ENVIANDO
                </>
              ) : (
                <>
                  <AiOutlineUpload />
                  {item?.url && " REENVIAR"}
                  {!item?.url && " ENVIAR"}
                </>
              )}
            </span>
            <input
              type="file"
              id={`file_upload_${type.id}`}
              style={{ display: "none" }}
              disabled={loading}
              onChange={(e) =>
                e?.target?.files && handleSend(e.target.files[0])
              }
            />
          </label>
        )}
      </div>
    </div>
  );
};
