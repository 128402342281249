import React, { useEffect, useState } from "react";

import { Container } from "./styles";

import {
  formatDate,
  normalizeBrl,
  normalizeCpfCnpj,
  subString,
} from "../utils/normalize";

import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";
import { ITitle } from "./ITitle";
import { TableComponent } from "../components/TableComponent";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaBarcode, FaInfoCircle, FaPrint } from "react-icons/fa";

const Billet: React.FC = () => {
  const { currentAssignor, getAllCompanies, companies } = useAuth();

  function handleDownload(selected) {
    window.open(
      `${process.env.REACT_APP_API}/tickets/${
        currentAssignor.token
      }/?titles=${selected.map((s) => s.id).join(",")}`
    );
  }

  function titleIsInRegistryOffice(title: ITitle) {
    return (
      title.billing_status_id ===
      title.collectingAgent?.title_in_registry_office_id
    );
  }

  function titleIsProtested(title: ITitle) {
    return (
      title.billing_status_id === title.collectingAgent?.protested_title_id
    );
  }

  useEffect(() => {
    getAllCompanies();
  }, [])

  return (
    <Container>
      <TableComponent
        title={
          <>
            <FaBarcode /> Boletos
          </>
        }
        dataType={[]}
        traitResponse={(data) =>
          data.map((d) => ({
            ...d,
            component_selectable:
              !titleIsInRegistryOffice(d) && !titleIsProtested(d),
          }))
        }
        labels={[
          {
            name: "operations.code",
            title: "Operação",
            formater: (_value, billet) => billet.operation.code,
          },
          {
            name: "operations.code",
            title: "Empresa",
            formater: (_value, billet) => billet?.company_code,
          },
          {
            name: "titles.code",
            title: "Título",
            formater: (_, title) => {
              return (
                <>
                  {title.code}
                  {title.product_id === 2 ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`cs-${title.id}`}>
                          <span>Cobrança Simples</span>
                        </Tooltip>
                      }
                    >
                      <Badge
                        variant="primary"
                        className="ml-1"
                        style={{ fontSize: "0.8rem" }}
                      >
                        CS
                      </Badge>
                    </OverlayTrigger>
                  ) : null}
                </>
              );
            },
          },
          {
            name: "payers.name",
            title: "Sacado",
            formater: (_value, billet) => (
              <div className="white-space-no-wrap">
                {subString(billet.payer.name, 14)}{" "}
                {billet?.payer?.name?.length > 14 ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-${billet.id}`}>
                        <span>{billet.payer.name}</span>
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle className="text-muted" />
                  </OverlayTrigger>
                ) : null}
                <br />
                {normalizeCpfCnpj(billet.payer.document_number)}
              </div>
            ),
          },
          {
            name: "titles.document_number",
            title: "Nr. Doc.",
            formater: (_value, billet) => billet.document_number,
          },
          {
            name: "titles.value",
            title: "Valor",
            formater: (_value, billet) => normalizeBrl(Number(billet.value)),
            align: "right",
          },
          {
            name: "titles.date",
            title: "Vencimento",
            formater: (_value, billet) => formatDate(billet.date),
          },
          {
            name: "our_number",
            title: "Nosso Nr.",
            ableToOrder: false,
            formater: (_value, billet) => (
              <>
                {billet.our_number}
                {billet.our_number_digit}
              </>
            ),
          },
          {
            name: "status",
            title: "Situação",
            ableToOrder: false,
            formater: (_value, billet) => {
              if (titleIsInRegistryOffice(billet)) {
                return <>Cartório</>;
              }
              if (titleIsProtested(billet)) {
                return <>Protesto</>;
              }

              return (
                <Button
                  type="button"
                  size="sm"
                  className="p-1"
                  onClick={() => handleDownload([billet])}
                >
                  <FaPrint /> Boleto
                </Button>
              );
            },
          },
        ]}
        dataReciever={async (params) => {
          const { data } = await api.get(`/assignor/tickets`, {
            params,
          });

          return data;
        }}
        filters={[
          {
            name: "operation_id",
            title: "Operação",
          },
          {
            name: "title_id",
            title: "Título",
          },
          {
            name: "payer_document",
            title: "CPF/CNPJ do Sacado",
            col: 2,
          },
          {
            name: "document_number",
            title: "Nr. Documento",
            col: 2,
          },
          {
            name: "start_date",
            title: "Vencimento (de)",
            type: "date",
            col: 2,
          },
          {
            name: "end_date",
            title: "Vencimento (até)",
            type: "date",
            col: 2,
          },
          {
            name: "product_id",
            title: "Produto",
            select: true,
            defaultValue: 1,
            options: [
              {
                title: "Antecipação de Recebíveis",
                value: 1,
              },
              {
                title: "Cobrança Simples",
                value: 2,
              },
            ],
            col: 2,
          },
          {
            name: "company_id",
            title: "Empresa",
            select: true,
            defaultValue: 0,
            options: companies?.map((company) => ({
              title: `${company?.code} - ${company?.fantasy_name}`,
              value: company?.id
            })),
            col: 3,
          },
        ]}
        batchActions={[
          {
            title: "Imprimir Boleto",
            onClick: (selected) => {
              handleDownload(selected);
            },
          },
        ]}
        exportAction={async (params) => {
          const obj = {
            ...params,
          };
          window.open(
            `${process.env.REACT_APP_API}/assignor/tickets?${Object.keys(obj)
              .filter((key) => obj[key])
              .map((key) => `${key}=${obj[key]}`)
              .join("&")}`
          );
        }}
      />
    </Container>
  );
};

export default Billet;
